@font-face {
  font-family: "sourcesans";
  src: url("./fonts/SourceSansPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sourcesansbold";
  src: url("./fonts/SourceSansPro-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sourcesanssemibold";
  src: url("./fonts/SourceSansPro-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "sourcesans";
}
a.nav-link.nav-link--icon:hover {
    color: red;
}
.project{
  color: #da322a !important;
  text-decoration: none !important;
}
.project:hover{
  text-decoration: underline !important;
}
.lang{
  width: 60px;
  text-align: center;
}
.lang span{
  font-size: 10px;
}
.tool-lang{
  color: black;
  font-weight: bold;
  text-decoration: none !important;
  border: 0px;
  height: 30px;
  background-color: inherit;
}
.tool-lang:hover{
  color: red;
}

@media (min-width: 992px) {
  body.body__transparent-header {
    padding-top: 0;
  }

  body.body__transparent-header .header {
    background-color: transparent;
    background-image: linear-gradient(#fff, rgba(255, 255, 255, 0));
  }
}
.header{
  background-color: transparent !important;
  background-image: linear-gradient(#fff, rgba(255, 255, 255, 0)) !important;
}

.section--header {
  padding-top: 80px;
  height: 400px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

@media (min-width: 992px) {
  .section--header {
    padding-top: 165px;
    height: 700px;
  }
}

.section--header h1 {
  background-color: #da322a;
  color: #fff;
  display: inline-block;
  white-space: pre-wrap;
  padding: 0 8px;
  font-weight: 300;
}

@media (min-width: 992px) {
  .section--header h1 {
    font-size: 4.0625rem;
  }
}

.header {
  background: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  transition: height 0.5s, padding 0.5s, background-color 0.5s, background-image 0.5s;
  height: 80px;
  padding: 0.25rem;
}

@media (max-width: 991.98px) {
  .header {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  }
}

@media (min-width: 992px) {
  .header {
    padding: 1.2rem 0 0;
    height: 165px;
  }
}

.header > .container > .row {
  -webkit-align-items: center;
          align-items: center;
}

@media (min-width: 992px) {
  .header > .container > .row {
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }
}

.header__brand {
  background-image: url(./assets/logo_metropolis_quadrat_gran\@2x.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  height: 60px;
  width: 60px;
  text-indent: -9999px;
  display: block;
  margin-left: 35px;
  transition: width 0.5s, height 0.5s;
}

@media (min-width: 768px) {
  .header__brand {
    margin-left: 15px;
    height: 70px;
    width: 70px;
  }
}

@media (min-width: 992px) {
  .header__brand {
    margin-left: 0;
    height: 120px;
    width: 120px;
  }
}

.header__nav {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  transition: padding 0.5s;
}

.header__actions {
  transition: padding 0.5s;
}

.header__actions__icon {
  width: 36px;
  height: 36px;
  display: block;
  text-indent: -9999px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 32px 32px;
}

@media (min-width: 992px) {
  .header__actions__icon {
    width: 42px;
    height: 42px;
  }
}

.header__actions__icon--share {
  background-image: url(./assets/share.svg?f61a775a69f7c3007ff23caa57538696);
}

.header__actions__icon--search {
  background-image: url(./assets/search.svg?edc5fe5c09a28fbcefa896ce4270817b);
}

.header__actions__amb {
  background-image: url(./assets/amb-header.svg?3b421d27cced4ad88f40e7d63ca572cd);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right center;
  width: 80px;
  height: 32px;
  text-indent: -9999px;
  display: block;
  margin-left: 0.5rem;
}

@media (min-width: 992px) {
  .header__actions__amb {
    width: 130px;
    height: 42px;
  }
}

.header__actions .nav {
  -webkit-align-items: center;
          align-items: center;
}

@media (min-width: 992px) {
  .header__actions .nav .nav-item {
    border-left: 1px solid #979797;
  }
}

.header__actions__user {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.header__actions__user .avatar {
  width: 36px;
  height: 36px;
  margin-right: 1rem;
}

.header--stuck {
  height: 80px;
  background-image: none;
  background-color: #fff !important;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
}

@media (min-width: 992px) {
  .header--stuck {
    padding: 0.25em 0;
  }

  .header--stuck .header__brand {
    width: 70px;
    height: 70px;
  }

  .header--stuck .header__nav {
    padding-top: 0.8rem;
  }

  .header--stuck .header__actions {
    padding-top: 0.8rem;
  }
}

.footer {
  padding: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.user {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden;
  background-color: #fff;
  padding: 1rem;
  color: #212529;
  border: 1px solid #e9ecef;
  border-radius: 3px;
  text-align: center;
}

.user__avatar {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

.user__name {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.2rem;
}

.user__town {
  margin-bottom: 0;
}

.user:hover {
  text-decoration: none;
}

.user__list {
  list-style: none;
  margin: 0.5rem 0 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  font-size: 0.9rem;
}

.user__list__item {
  background-color: #3490dc;
  color: #fff;
  border-radius: 3px;
  padding: 0.15rem 0.5rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.user__list--sectors .user__list__item {
  background-color: #38c172;
}

.user__list--innovation .user__list__item {
  background-color: #f66d9b;
}

.users__pagination {
  margin-top: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.avatar {
  width: 64px;
  height: 64px;
  border: 1px solid #ced4da;
  background-color: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
}

.avatar img {
  display: block;
  width: 100%;
}

.avatar--empty {
  background: #ced4da url(./assets//avatar.svg?0edfccadb7f473710958dadf75ff7702) no-repeat center/cover;
}

.share {
  background-color: #463c32;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 165px;
  left: 0;
  width: 100%;
  z-index: 0;
  color: #fff;
  padding: 45px 0;
  -webkit-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
          transform: translateY(-100%);
  opacity: 0;
  transition: top 0.25s, opacity 0.25s, -webkit-transform 0.25s;
  transition: top 0.25s, transform 0.25s, opacity 0.25s;
  transition: top 0.25s, transform 0.25s, opacity 0.25s, -webkit-transform 0.25s;
}

.share.is-active {
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  z-index: 10;
}

.share__close {
  border-radius: 50%;
  border: 1px solid #fff;
  color: #fff;
  width: 42px;
  height: 42px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.share__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}

.share__list__item {
  padding: 0 20px;
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
}

.share__list__item__link {
  background: url(./assets//facebook.svg?220e3090299376dd5a9d7ee7dec2e83b) no-repeat center right;
}

.share__list__item__link--twitter {
  background-image: url(./assets//twitter.svg?caaafc20e390d0c2e5355741431ba084);
}

.share__list__item__link--linkedin {
  background-image: url(./assets//linkedin.svg?f2753062cf142d61d4ca074b35c7ff31);
}

.share__list__item__link--whatsapp {
  background-image: url(./assets//whatsapp.svg?e665971fee0122d7835d97be86b9ad90);
}

.share__list a {
  color: #fff;
  border: 1px solid #fff;
  border-width: 1px 0;
  padding: 0.5rem 0;
  display: -webkit-flex;
  display: flex;
  width: 100%;
}

.share__list a span {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.share__list a svg {
  width: 20px;
  height: 20px;
}

.share__list a:hover {
  text-decoration: none;
  color: #3490dc;
}

.header--stuck + .share {
  top: 80px;
}

.footer {
}

.footer > .container {
}

#ss_survey_widget{
width: 1500px!important;
}

.footer .nav {
  font-weight: 700;
  font-size: 0.7rem;
}

.footer .nav--large {
  font-size: 1.1rem;
}

.footer .nav .nav-link {
  color: #000;
}

.footer .nav .nav-link:hover {
  color: #3490dc;
}

.footer .logo {
  width: 200px;
  height: 30px;
}

.logo {
  display: block;
  background-size: cover;
  background-position: center;
  text-indent: -9999px;
}

.logo--amb-peu {
  background-image: url(./assets//AMB_peu.png?fd78cb84f9263bc1aef5d28ae1d2a874);
}

.logo--feder {
  background-image: url(./assets//feder-ca.jpg?ef63918e3d239640943b020fde5613b1);
}

.aside {
  background-color: #eee;
  border-right: 2px solid #da322a;
  position: fixed;
  top: 0;
  height: 100vh;
  left: -70%;
  width: 70%;
  box-shadow: 10px 0 25px 0 rgba(0, 0, 0, 0.1);
  z-index: 999;
  padding: 3rem 2rem;
  transition: left 0.25s;
}

@media (min-width: 992px) {
  .aside {
    left: -489px;
    width: 489px;
  }
}

.aside.is-active {
  left: 0;
}

.aside__toggle {
  background-color: #da322a;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 100%;
  top: 1.1rem;
  transition: top 0.5s;
}

@media (min-width: 992px) {
  .aside__toggle {
    top: 2rem;
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 992px) {
  .aside__toggle--stuck {
    top: -0.9rem;
  }
}

.aside__nav .nav-item {
  border-bottom: 1px solid #463c32;
}

.aside__nav .nav-link {
  color: #463c32;
  font-size: 1.2rem !important;
  font-weight: 500;
  padding-left: 0;
}

.aside__nav .nav-link--icon {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.aside__toggle__icon {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  transition: background 0.3s;
  background: none;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 992px) {
  .aside__toggle__icon {
    width: 50px;
    height: 50px;
  }
}

.aside__toggle__icon:focus {
  outline: none;
}

.aside__toggle__icon span {
  display: block;
  position: absolute;
  top: 18px;
  left: 10px;
  right: 10px;
  height: 2px;
  background: white;
  transition: background 0s 0.3s;
  border-radius: 2px;
}

@media (min-width: 992px) {
  .aside__toggle__icon span {
    top: 23px;
  }
}

.aside__toggle__icon span:before,
.aside__toggle__icon span:after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  content: "";
  border-radius: 2px;
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0s;
}

.aside__toggle__icon span:before {
  top: -8px;
  transition-property: top, background, -webkit-transform;
  transition-property: top, transform, background;
  transition-property: top, transform, background, -webkit-transform;
}

.aside__toggle__icon span:after {
  bottom: -8px;
  transition-property: bottom, background, -webkit-transform;
  transition-property: bottom, transform, background;
  transition-property: bottom, transform, background, -webkit-transform;
}

.aside__toggle__icon.is-active span {
  background: none !important;
}

.aside__toggle__icon.is-active span:before,
.aside__toggle__icon.is-active span:after {
  transition-delay: 0s, 0.3s;
}

.aside__toggle__icon.is-active span:before {
  top: 0;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}

.aside__toggle__icon.is-active span:after {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.section--video {
  height: 900px!important;
  position: relative;
  background-image: url("https://hubtalent.peninsula.co/images/hubtalent-home-os.jpg");
  background-size: cover;
  background-position: 50% 65%;
  opacity: 0.8;
}


@media (min-width: 992px) {
  .section--video {
    height: 300px;
  }
}

.section--video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.section--image {
  background-size: cover;
  background-position: center;

}

.imghome1{
 width: 100%;
 height: auto;
}

.imghome2{
 width: 100%;
 height: auto;
}


  .section--search {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: left;
          align-items: left;
 padding: 23em  40em 3em 8em!important;
}


.sectionred{
 background-color: red;
}
.sectionred2{
 background-color: #ef0d06;
}
.reptescontainer{
 padding-top: 3em;
 padding-bottom: 3em;
 padding-left: 4em;
 padding-right: 4em!important;
}

.sectiongrey{
 background-color: #595959;
}

.sectionnews{
 background-color: red;
 padding-top: 3em;
 padding-bottom: 3em;
 padding-left: 4em;
 padding-right: 4em!important;
}
.sectioncal{
 background-image:url(./assets/hubtalent-home00005red.jpg);
 padding-top: 3em;
 padding-bottom: 3em;
 padding-left: 3em;
 padding-right: 3em!important;
  height: 900px!important;
  position: relative;
  background-size: cover;
  background-position: 50% 65%;
}

.calendaritem{
color: white!important;
font-size: 90px;
}

.calendartitle{
font-size:20px;
margin-top:100px;
margin-left: 3em;
margin-right: 6em!important;
}


@media only screen and (min-width: 1500px) {
  .section--search {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: left;
          align-items: left;
 padding: 30em  70em 3em 8em!important;
}
}
.blackbk{
 background-color: black;
   position:relative;
  z-index: 111;
}

.col-imgred{
width: 50%!important;
}

.col-imgwhite{
width: 66%!important;
}

.col-txtred{
width: 35%!important;
padding: 2em 2em 2em 10em;
}

.col-txtwhite{
width: 30%!important;
padding: 60px 60px 60px 60px;
background-color: white;
color: red!important;
margin-left: -120px;
height: 100%;
margin-top: 150px;
}

.col-txtwhite h1 {
color: red!important;
}

.homewhitetitle{
 color: #fff;
  font-size: 80px;
  text-align: center!important;
 padding-bottom: 40px;
}

.col-txtwhite p {
  color: red!important;
  font-size: 20px;
  margin-bottom: 30px;
}
.section--search .section__content {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 100%;
}
.section--search .section__content h1 {
  color: #fff;
  font-size: 80px;
  text-align: left!important;
  padding-rigt: 800px;
}

.sectionred h1 {
  color: #fff;
  font-size: 80px;
}
.sectionred p {
  color: #fff;
  text-align: left!important;
  font-size: 20px;
}

.button-home{
  background: red !important;
  color: white !important;
  border: none!important;
  border-radius: 1px!important;
  font-size: 25px !important;
  padding: 0.7em 1em 0.7em 1em !important;
}

.button-homew{
 background: white !important;
 color: black !important;
 border: none!important;
 border-radius: 1px!important;
 font-size: 25px !important;
 padding: 0.7em 1em 0.7em 1em !important;
}

.reptesbutton{
align-content: center!important;
top: 50px;
position: relative;
margin: 0;
margin-bottom: 3em;
  display: flex;
  align-items: right;
  justify-content: right;
}
.section--search .section__content h2 {
  margin-top: 1rem;
  color: #fff;
  font-size: 1.1rem;
}

.section--search .section__content .btn {
  margin: 0 auto;
}

.section--gray {
  background-color: #f5f5f5;
}

.section--graydark {
  background-color: #5a5a5a;
}

.breadcrumb {
  display: -webkit-inline-flex;
  display: inline-flex !important;
  font-weight: 700;
  flex-wrap: wrap;
  padding: 0.25rem 0.5rem;
  margin-bottom: 0;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.breadcrumb .breadcrumb-item {
  color: #999;
}

.breadcrumb .breadcrumb-item a {
  color: #463c32;
}

.breadcrumb .breadcrumb-item a:hover {
  text-decoration: none;
  color: #da322a;
}

.breadcrumb .breadcrumb-item--icon span {
  display: none;
}

.breadcrumb .breadcrumb-item--icon svg {
  position: relative;
  top: -2px;
}

.breadcrumb .breadcrumb-item--icon:hover svg {
  fill: #da322a;
}

.header-actions .btn {
  border: 1px solid #e9ecef;
  border-radius: 0.25rem;
}

.home-icon {
  max-width: 100px;
  border-radius: 6px;
}

.map-form {
  height: 400px;
  background-color: #444;
}

.file-upload-preview .custom-file {
  margin-right: 1rem;
}

.file-upload-preview .custom-file-label {
  text-overflow: ellipsis;
  word-wrap: none;
}

.file-upload-preview__image {
  background: #edede8 url(./assets/picture.svg?494e83bf99f55558b44d7c81ff3d8acb) no-repeat center/50px 50px;
  width: 100%;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.file-upload-preview__image img {
  width: 100%;
  object-fit: contain;
  display: block;
}

.file-upload-preview__image--avatar {
  width: 100px;
  height: 100px;
  position: relative;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
}

.file-upload-preview__image--avatar img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image-placeholder {
  background: #edede8 url(./assets/picture.svg?494e83bf99f55558b44d7c81ff3d8acb) no-repeat center/24px 24px;
  border-radius: 3px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  overflow: hidden;
}

.image-placeholder img {
  width: 100%;
  object-fit: cover;
}

.card-header h2 {
  margin-bottom: 0;
  font-size: 1.2rem;
}

.admin-table {
  list-style: none;
  margin: 0;
  padding: 0;
}

.admin-table__row {
  border-top: 1px solid #ced4da;
}

.admin-table__row:first-child {
  border-top-width: 0;
}

.admin-table__row__link {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #212529;
}

.admin-table__row__link:hover {
  background-color: #f5f5f5;
  text-decoration: none;
}

.admin-table__cell {
  position: relative;
  padding: 1rem 0.5rem;
}

.admin-table__cell__bubble {
  background-color: #e3342f;
  color: #fff;
  position: absolute;
  top: 0.25rem;
  left: -0.25rem;
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem;
  line-height: 1;
}

.admin-table__cell .image-placeholder {
  width: 64px;
  height: 64px;
}

.admin-table__cell--grow {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.admin-table__title {
  font-size: 1.1rem;
  margin-bottom: 0;
}

.admin-table-status {
  background-color: #333;
  color: #fff;
  padding: 0.25rem 0.75rem;
  border-radius: 3px;
  width: 140px;
  margin: 0 auto;
  text-align: center;
}

.admin-table-status--rejected {
  background-color: #dd1367;
}

.admin-table-status--pending-review,
.admin-table-status--needs-improvement {
  background-color: #00689d;
}

.admin-table-status--publish {
  background-color: #3f7e44;
}

.ods {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-inline-flex;
  display: inline-flex;
}

.ods__item {
  border-radius: 3px;
  padding: 0.5rem;
  margin-left: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ods__item:first-child {
  margin-left: 0;
}

.ods__item--1 {
  color: #e5243b;
  background-color: #fbdade;
}

.ods__item--2 {
  color: #dda73a;
  background-color: #fbf5e8;
}

.ods__item--3 {
  color: #4c9f38;
  background-color: #c4e7bc;
}

.ods__item--4 {
  color: #c5192d;
  background-color: #f6b4bc;
}

.ods__item--5 {
  color: #ff3a21;
  background-color: #ffefed;
}

.ods__item--6 {
  color: #26bde2;
  background-color: #daf4fa;
}

.ods__item--7 {
  color: yellow;
  background-color: #ffffcc;
}

.ods__item--8 {
  color: #a21942;
  background-color: #ef98b2;
}

.ods__item--9 {
  color: #fd6925;
  background-color: #fff4ef;
}

.ods__item--10 {
  color: #dd1367;
  background-color: #fac2d9;
}

.ods__item--11 {
  color: #fd9d24;
  background-color: #fff7ee;
}

.ods__item--12 {
  color: #bf8b2e;
  background-color: #f2e2c7;
}

.ods__item--13 {
  color: #3f7e44;
  background-color: #b1d8b4;
}

.ods__item--14 {
  color: #0a97d9;
  background-color: #b3e5fc;
}

.ods__item--15 {
  color: #56c02b;
  background-color: #d2f2c5;
}

.ods__item--16 {
  color: #00689d;
  background-color: #6acdff;
}

.ods__item--16 {
  color: #00689d;
  background-color: #6acdff;
}

.ods-square {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
}

.ods-square__item {
  width: 64px;
  height: 64px;
  background-size: cover;
  background-position: center;
  text-indent: -9999px;
}

.ods-picker {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[3];
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

@media (min-width: 768px) {
  .ods-picker {
    -ms-grid-columns: (1fr)[4];
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 992px) {
  .ods-picker {
    -ms-grid-columns: (1fr)[5];
    grid-template-columns: repeat(5, 1fr);
  }
}

.ods-picker__item {
  position: relative;
}

.ods-picker__item input {
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: none;
}

.ods-picker__item input:checked + .ods-picker__item__badge {
  -webkit-filter: none;
          filter: none;
}

.ods-picker__item__badge {
  width: 100%;
  padding-bottom: 100%;
  text-indent: -9999px;
  background-size: cover;
  display: block;
  margin-bottom: 0;
  height: 0;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  transition: -webkit-filter 0.15s;
  transition: filter 0.15s;
  transition: filter 0.15s, -webkit-filter 0.15s;
  cursor: pointer;
}

.ods-picker__item__badge:hover {
  -webkit-filter: none;
          filter: none;
}

.ods-square__item--1,
.ods-picker__item__badge--1 {
  background-image: url(./assets/1.jpg?d5cf71bdad14ed1517ee537ac28dc2f6);
}

.ods-square__item--2,
.ods-picker__item__badge--2 {
  background-image: url(./assets/2.jpg?76af1d667d1bd21e8266f6570f5edaae);
}

.ods-square__item--3,
.ods-picker__item__badge--3 {
  background-image: url(./assets/3.jpg?31f17aff52ca8f4df51e1fe61115b50a);
}

.ods-square__item--4,
.ods-picker__item__badge--4 {
  background-image: url(./assets/4.jpg?833d72ba9e1ef2799345cd579d47fe13);
}

.ods-square__item--5,
.ods-picker__item__badge--5 {
  background-image: url(./assets/5.jpg?1ce9f225313d8e9f056e7e7d6e792c78);
}

.ods-square__item--6,
.ods-picker__item__badge--6 {
  background-image: url(./assets/6.jpg?b7b84a3dc3378c8a5fb2feeca1592ea2);
}

.ods-square__item--7,
.ods-picker__item__badge--7 {
  background-image: url(./assets/7.jpg?9072d1597b1bf02747ded1495546209b);
}

.ods-square__item--8,
.ods-picker__item__badge--8 {
  background-image: url(./assets/8.jpg?cae7795dbf26668ee7ec09d2f270bafe);
}

.ods-square__item--9,
.ods-picker__item__badge--9 {
  background-image: url(./assets/9.jpg?09e17d60632adac3ec30ef6e851784ae);
}

.ods-square__item--10,
.ods-picker__item__badge--10 {
  background-image: url(./assets/10.jpg?59a614b33fd413b86cc70c4b79cfee23);
}

.ods-square__item--11,
.ods-picker__item__badge--11 {
  background-image: url(./assets/11.jpg?8d8f9de53d4ca995733a2a7f3d9c0e5c);
}

.ods-square__item--12,
.ods-picker__item__badge--12 {
  background-image: url(./assets/12.jpg?97660df5888e401861d70b786445c640);
}

.ods-square__item--13,
.ods-picker__item__badge--13 {
  background-image: url(./assets/13.jpg?e75485befe14df067e7baf312334392f);
}

.ods-square__item--14,
.ods-picker__item__badge--14 {
  background-image: url(./assets/14.jpg?bde29f9d0d6a876208d3daa9e830daf0);
}

.ods-square__item--15,
.ods-picker__item__badge--15 {
  background-image: url(./assets/15.jpg?3187908cb915a2f0d56a178fab47568c);
}

.ods-square__item--16,
.ods-picker__item__badge--16 {
  background-image: url(./assets/16.jpg?352b2ce1903ab24e08725cebba47d166);
}

.ods-square__item--17,
.ods-picker__item__badge--17 {
  background-image: url(./assets/17.jpg?2be463f04400e3f581acd9087beb578a);
}
.btn-primary {
    color: #fff;
    background-color: #da322a !important;
    border-color: white !important;
}
.btn.btn-primary {
    font-weight: 700;
}
.btn-primary:hover {
    color: #fff !important;
    background-color: #bd2821 !important;
    border-color: #b2261f !important;
}

.btn.btn-outline-primary {
  border-color: #000;
}

.btn img {
  width: 18px;
  height: 18px;
}

.btn.btn-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.btn.btn-icon img {
  margin-right: 0.5rem;
}

.project {
  background-color: white;
  border-radius: 3px;
  overflow: hidden;
  display: block;
  height: 100%;
}

.project__image {
  background: #edede8 url(./assets/picture.svg?494e83bf99f55558b44d7c81ff3d8acb) no-repeat center/64px 64px;
  height: 250px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  overflow: hidden;
}

.project__image img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.project__content {
  padding: 1.5rem;
  position: relative;
}

.project__avatar {
  position: absolute;
  top: -32px;
  right: 1.5rem;
}

.project__author {
  text-transform: uppercase;
  margin-bottom: 0;
}

.project__title {
  font-size: 1.1rem;
}

.project-grid {
  display: -ms-grid;
  display: grid;
  width: 100%;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: 200px;
  grid-template-rows: 200px;
  grid-auto-flow: dense;
}

@media (min-width: 768px) {
  .project-grid {
    -ms-grid-columns: (1fr)[3];
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 992px) {
  .project-grid {
    -ms-grid-columns: (1fr)[4];
    grid-template-columns: repeat(4, 1fr);
    -ms-grid-rows: 300px;
    grid-template-rows: 300px;
  }
}

.project-grid__item {
  position: relative;
  overflow: hidden;
  background-color: #444;
  color: #fff;
  display: block;
  width: 100%;
  /*@include media-breakpoint-up(lg) {
      height: 300px;
  }*/
}

.project-grid__item__image {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

.project-grid__item__image img {
  display: block;
  min-height: 100%;
  width: 100%;
  object-fit: cover;
}

.project-grid__item__author {
  text-transform: uppercase;
  margin-bottom: 0;
}

.project-grid__item__title {
  margin-bottom: 0;
  font-size: 1.1rem;
}

.project-grid__item__enrolments:before {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  margin: 0 auto;
  background: url(./assets/timer.svg?7a3f158babfa3cc6634635f82a290d8d) no-repeat center/cover;
}

.project-grid__item__content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
}

.project-grid__item:hover {
  color: #fff;
}

.project-grid__item:hover .project-grid__item__image {
  -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
          transform: scale(1.2);
}

@media (min-width: 768px) {
  .project-grid__item {
    /*&:nth-child(10) {
        grid-column-end: span 2;
        grid-row-end: span 2;
    }*/
  }

  .project-grid__item:nth-child(2) {
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  .project-grid__item:nth-child(4),
  .project-grid__item:nth-child(13) {
    -ms-grid-row-span: 2;
    grid-row-end: span 2;
  }
}

.project-grid__add {
  background-image: linear-gradient(227deg, #2a317f 0%, #985ba5 100%);
  color: #fff;
  padding: 1rem;
  text-align: center;
}

.project-grid__add:hover {
  color: #fff;
  text-decoration: none;
}

.challenges .project-grid__item {
  display: block;
  border-radius: 1rem;
  overflow: hidden;
  min-height: 100%;
}

.comments {
  list-style: none;
  margin: 0;
  padding: 0;
}

.comment {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 2rem;
}

.comment__avatar {
  -webkit-flex: 0 0 64px;
          flex: 0 0 64px;
  margin-right: 1rem;
  height: 64px;
}

.comment__body__date {
  color: #6c757d;
  font-weight: 700;
}

.revisions {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.revisions__item {
  margin-bottom: 1rem;
  max-width: 70%;
  -webkit-align-self: flex-start;
          align-self: flex-start;
}

.revisions__item--right {
  -webkit-align-self: flex-end;
          align-self: flex-end;
}

.revision__comment {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 1rem;
  border-radius: 0.25rem;
}

.event {
  display: block;
  border: 1px solid #e9ecef;
  background-color: #fff;
  border-radius: 3px;
  padding: 1.5rem;
  color: #000;
}

.event .event-date {
  margin-bottom: 1rem;
}

.event:hover {
  text-decoration: none;
  color: #000;
  border-color: #da322a;
}

.event:hover h3 {
  color: #da322a;
}

.faq .card-body ul li {
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.faq .card-body ul li strong,
.faq .card-body ul li a {
  display: block;
}

.faq__menu {
  position: -webkit-sticky;
  position: sticky;
  top: 120px;
}

.ods-literals {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ods-literals li {
  position: relative;
  min-height: 64px;
  padding-left: 72px;
  margin-bottom: 1rem !important;
}

.ods-literals li img {
  position: absolute;
  width: 64px;
  height: 64px;
  top: 0;
  left: 0;
}

.single-user__avatar {
  width: 100px;
  height: 100px;
}

.single-user__map {
  height: 300px;
  background-color: #666;
}

.module {
  margin-top: 3rem;
}

.module__header {
  border-top: 2px solid #463c32;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.module__content {
  border-top: 2px solid #463c32;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.terms-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  font-size: 1.2rem;
}

.terms-list dt,
.terms-list dd {
  border-top: 1px solid #ddd;
  padding: 0.5rem 0;
  margin-bottom: 0;
}

.terms-list dt:first-child,
.terms-list dt:nth-child(2),
.terms-list dd:first-child,
.terms-list dd:nth-child(2) {
  border-top-width: 0;
}

.terms-list dt {
  width: 30%;
}

.terms-list dd {
  margin-left: 2%;
  width: 68%;
}

.account h1 {
  font-size: 1.5rem;
  margin-bottom: 0;
}

.account .header__separator {
  color: #6c757d;
  font-weight: 400;
}

.account .lead {
  margin-bottom: 0;
  color: #6c757d;
}

.account .nav .nav-link {
  color: #212529;
  padding-left: 0;
  font-size: 1rem;
}

.account .nav .nav-link.active {
  font-weight: 700;
}

.login {
  display: -webkit-flex;
  display: flex;
  background-color: #fff;
  height: 100vh;
}

.login__banner {
  background: #ef5b9c url(./assets/bg1.svg?0113d91cff33397c8f9f00f4e327ddfd) no-repeat center/cover;
  width: 30%;
}

.login__section {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 3rem;
}

.login__section form {
  max-width: 100%;
  width: 500px;
}

.login__section form label {
  font-weight: 700;
}

.login__section__header {
  max-width: 100%;
  width: 500px;
}

.login__section__footer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  width: 100%;
}

.login .hub-talent {
  display: block;
  background-image: url(./assets/metropolis-barcelona.png?30df017a80a38239cba27841ac31d29a);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  width: 100px;
  height: 100px;
  text-indent: -9999px;
}

.login .amb {
  display: block;
  background: url(./assets/AMB_full.svg?b26f1c7c7607aa266fb8097a2740ef33) no-repeat center/cover;
  width: 270px;
  height: 80px;
  text-indent: -9999px;
}

.map-layout {
  display: -webkit-flex;
  display: flex;
}

.map-layout__aside {
  width: 300px;
  padding: 1rem;
  background-color: #f5f5f5;
}

.map-layout__map {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  height: 500px;
  background-color: #333;
}

.map-user {
  text-align: center;
}

.map-user .avatar {
  margin: 0 auto 0.5rem;
}

.toggle {
  display: -webkit-flex;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.toggle__item {
  margin-left: 0.5rem;
}

.toggle__item__button {
  display: block;
  padding: 1rem;
}

.toggle__item__button--active {
  background-color: #f5f5f5;
}

.toggle__item__button svg {
  width: 24px;
  height: 24px;
}

.toggle__item__button:hover {
  background-color: #da322a;
}

.toggle__item__button:hover svg {
  fill: #fff;
}

.event-date {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  line-height: 1;
}

.event-date__day {
  font-size: 2.4rem;
  margin-right: 0.25rem;
}

.event-date__month strong {
  display: block;
}

.single-challenge .module__content .btn-repte {
  border: 1px solid #000;
  margin: 0 auto !important;
}

.applicants {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.applicant {
  display: block;
  text-align: center;
  padding: 0 0.5rem;
}

.applicant .avatar {
  margin: 0 auto 0.5rem;
}

.page h1 {
  font-size: 3rem;
  text-transform: uppercase;
}





.header-home{
  height: 900px!important;
  position: relative;
  background-image: url(./assets/home-hub2.jpg)!important;
  background-size: cover;
  background-position: 50% 65%;
}
.header-home h1{
  color: #fff;
  font-size: 80px;
  text-align: left!important;
}
.col-txtwhite-v2{
  background-color: white;
  color: red!important;
}

.col-txtwhite-v2 h1, .col-txtwhite-v2 p {
  color: red!important;
}

.quin-es-el-teu-repte{
  background-image: url(./assets/hubtalent-home00002.jpg);
  background-size: cover;
  background-position: center;
}
.agenda-proper{
  background-image: url(./assets/hubtalent-home00005red.jpg);
  background-size: cover;
  background-position: center;
}

@media (max-width: 992px) {
  .header-home{
    height: 500px!important;
    background-position: 50% 65%;
  }
  .header-home h1{
    font-size: 50px;
    line-height: 50px;
  }
  .sectionred h1{
    font-size: 50px;
  }
  .homewhitetitle{
    font-size: 50px;
  }
}

.hubicon {
  -webkit-filter: brightness(50%) saturate(200%) hue-rotate(90deg);
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
small{
  font-size: 0.75em !important;
  color: white !important;
}
.sectionred2 label {
    font-size: 12px;
}

/*alerts*/
.swal2-styled.swal2-confirm{
  background-color: #ef0d06 !important;
}
.swal2-icon.swal2-error{
  color:  #ef0d06 !important;
}
